export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    // scrolled styles
    '.container': {
      '.smallNavMenu': {
        '.navItem': {
          color: 'light'
        }
      }
    },
    '.containerScrolled': {
      padding: '0.5rem 1rem'
    },
    // global nav classes
    '.hamburger, .hamburgerOpen': {
      border: 'solid 1px',
      borderColor: 'secondary',
      backgroundColor: '#89612321',
      padding: '0.5rem',
      height: '40px',
      width: '40px',
      transform: 'skewX(-5deg)'
    },
    '.hamburgerOpen': {
      padding: '0rem',
      div: {
        backgroundColor: 'secondary'
      }
    },

    '.navMenuOpen': {
      padding: '2rem',
      '.navItem': {
        a: {
          color: 'secondary'
        }
      }
    },
    '.phoneContainer': {
      borderTop: '1px solid white',
      borderColor: 'dark',
      py: '1rem',
      mx: '0rem'
    }
  },

  footer: {
    borderTop: '1px solid lightgrey',
    backgroundColor: 'primary',
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'grey'
        }
      }
    },
    '.logo': {},

    '.sectionHeading': {
      color: 'secondary',
      fontWeight: '500',
      borderColor: 'secondary'
    },

    '.poweredByText': {
      margin: ['auto', '', '0rem 0rem 0rem 0rem'],
      textAlign: 'right',
      '.gonationLogo': {
        filter: 'brightness(0) invert(0.6)'
      }
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'black'
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start']
    }
  },

  ctaWidget: {
    // display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    borderBottom: '2px solid',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['2rem', '2.25rem', '2.5rem', '2.75rem'],
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 'bolder',
    letterSpacing: '2px',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem',
    // textShadow:
    //   '#896d36 -2.5px -2.5px 0px, #896d36 2.5px -2.5px 0px, #896d36 -2.5px 2.5px 0px, #896d36 2.5px 2.5px 0px',

    WebkitTextStroke: '2px #896d36'
  },

  subtitle: {
    color: 'secondary',
    fontWeight: 'bold',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'body',
    fontSize: ['1rem', '1.25rem', '1.5rem'],
    textTransform: 'uppercase',
    marginBottom: '0.75rem'
  },

  text: {
    order: '3'
  },

  sideBySide1: {
    overflow: 'hidden',
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/gplay.png)',
    '.content': {
      order: ['', '', '2'],
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      order: '1',
      width: ['', '', '55%'],
      height: ['', '', '75vh'],
      '::before': {
        background: 'unset'
      },
      img: {
        padding: ['1rem', '', '2rem', '2rem', '3rem']
        // height: '100vh',
      }
    },
    '.linksContainer': {
      mt: '2.5rem',
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    overflow: 'hidden',
    flexDirection: ['', '', 'row'],
    '.imageContainer': {
      order: '2',
      width: ['', '', '60%'],
      height: ['', '', '95vh'],
      position: 'relative',
      '::before': {
        content: ['none', 'none', "''"],
        border: 'solid 3px',
        borderColor: 'secondary',
        position: 'absolute',
        height: '85%',
        width: '50vw',
        left: '10%',
        top: '15%'
      },
      img: {
        // height: '100vh',
        border: 'solid 3px',
        borderColor: 'secondary'
      },
      '.image1': {
        position: ['', '', 'absolute'],
        top: '0%',
        left: '25%',
        width: ['', '', '75%'],
        height: ['', '', '75%']
      },
      '.image3': {
        position: ['', '', 'absolute'],
        bottom: '10%',
        width: ['', '', '60%'],
        height: ['', '', '50%']
      }
    },

    '.content': {
      order: '2',
      width: ['', '', '40%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      '.linksContainer': {
        mt: '1.5rem',
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      border: 'none',
      width: ['100%', '', '90%'],
      maxWidth: 'unset',
      margin: '0rem auto',
      height: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      padding: '0rem',
      fontSize: ['4rem', '4.5rem', '6rem', '7rem', '7.5rem'],
      textShadow: '2px 2px 10px black'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white',
      fontWeight: 'normal'
    },
    '.text': {
      color: 'white'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    '.imageContainer': {
      order: ['2', '', '2'],
      maxHeight: ['50vh', '', '50vh', '', '60vh']
    },
    // maxHeight: ['', '', '30vh', '30vh'],
    '.content': {
      order: ['1', '1'],
      padding: ['1rem', '', '', '2rem', '3rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      width: ['', '', '40%', '60%', '65%'],
      backgroundImage: 'url(https://www.transparenttextures.com/patterns/dimension.png)'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.25rem', '1.5rem', '1.75rem'],
      textAlign: 'left',
      margin: '.75rem 0rem',
      fontWeight: 'normal',
      border: 'none',
      width: 'fit-content'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '.75rem 0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset',
      fontSize: ['1rem', '1.25rem', '1.5rem']
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem',
      order: '2'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        bg: 'dark',
        padding: '1rem',
        fontSize: '1rem',
        '&:hover': {
          bg: 'secondary',
          color: 'white',
          transition: 'all 0.3s ease-in-out'
        }
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2',
    '.section': {
      margin: '0rem 0rem',
      padding: '2rem 10%',
      alignItems: 'flex-start',
      justifyContent: 'center'
    },

    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      '> *': {
        color: 'white'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2',
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/hexellence.png)'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    border: 'solid 1px black',
    padding: ['1rem', '1rem'],
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      color: 'secondary',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      textTransform: 'uppercase',
      color: 'light',
      margin: ['1rem', '', '1rem 1.5rem'],
      paddingBottom: '1rem',
      textAlign: 'left',
      fontSize: ['1.5rem', '', '2rem']
    },

    '.menuItemName': {
      color: 'dark',
      fontFamily: 'heading',
      fontWeight: '500',
      fontSize: ['1.2rem', '1.3rem', '', '']
    },

    '.menuSectionDescription': {
      fontSize: '1.1rem',
      textAlign: 'left',
      margin: ['1rem', '', '1rem 1.5rem'],
      color: 'dark'
    },
    '.menuItemDescription': {
      color: 'secondary'
    },
    '.menuPriceContainer': {
      fontFamily: 'heading',
      // display: 'none',
      color: 'black'
    },
    '.menuItemPrice': {
      color: 'dark'
    },
    '.variantsContainer': {
      color: 'black'
      // display: 'none',
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    // '.gn-events-wrap': {
    //   display: 'none'
    // },
    display: 'flex',
    flexDirection: 'column',
    '.special-events-container': {
      order: -1
    },
    '.no-events-container': {},
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    },
    '.eventItemImage': {
      maxHeight: '400px'
    },
    '.title': {
      width: '100%',
      textAlign: 'center',
      display: 'block'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },
  locationMap: {
    order: '2',
    backgroundColor: 'secondary',
    color: 'white',
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'white'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }

    // '.hours-section': {
    //   '::after': {
    //     content: "'Bar Hours - 7 Days - 11:30am to Close'",
    //     marginTop: '1rem',
    //     display: 'flex',
    //     fontWeight: 'bold',
    //     fontSize: ['1.1rem']
    //   }
    // }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ===============================
  // ? ====  Private Dining Menu  ====
  // ? ===============================

  privateEvents: {
    variant: 'customVariants.sideBySide1',

    background: 'none'
  },

  // ? ===============================
  // ? ====  Private Dining Menu  ====
  // ? ===============================

  catering1: {
    variant: 'customVariants.sideBySide1',
    background: 'none'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      backgroundColor: '#c5c2c2',
      margin: ['1rem', '', '2rem'],
      width: ['100%', 'calc(50% - 0.5rem * 2)', 'calc(33.3% - 2rem * 2)']
    },
    '.imageContainer': {
      backgroundColor: 'black',
      img: {
        objectFit: 'contain'
      }
    },
    '.subtitle': {
      color: '#5d574e'
    }
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
