export default {
  pageHeroV1: {
    container: {
      height: '75vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'center',
      justifyContent: 'center'
    },

    content: {
      flexDirection: 'column',
      color: 'text',
      textAlign: 'center',
      h1: {
        variant: 'customVariants.title',
        fontSize: ['1.75rem', '2.5rem', '3.5rem', '4rem', '5rem'],
        marginBottom: 1,
        textTransform: 'uppercase',
        color: 'light'
      }
    }
  }
}
